import React,{ useState}  from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../components/FormikControl';
import { BarLoader} from 'react-spinners'
import Alert from '@material-ui/lab/Alert';
// import { Box, Button, Card, CardContent, Checkbox, CheckboxProps, FormControlLabel, FormGroup, MenuItem, TextField, Typography } from '@material-ui/core';
import "../containers/Formikcss.css";
import "../containers/Login.css";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { Box } from '@material-ui/core';


function BannerPush () {
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [countryCodeOptions, setCountryCodeOptions] = useState([{ key: 'Select an option', value: '' }]);
  const [bu, setBU] = useState('');
  const [responsemessage, setResponsemessage] = useState(null);
  const history = useHistory();
  const [inputNotificationFile, setNotificationInputFile] = useState(null);

  function isValidPushHouseHold(message) {
    return this.test("isValidPushHouseHold", message, function (value) {
      const { path, createError } = this;
  
      if (!inputNotificationFile && !value) {
        return createError({ path, message: message });
      }
      var exp=/[^\d,_]+/g
      if(bu && bu.toLowerCase()=='itam')
        exp=/[^a-zA-Z\d,-_]+/g
      var flag=exp.test(value)
      
      if (value && flag) {
        return createError({ path, message: message });
      }
      // if(value && value.split(',').length>10){
      //   return createError({ path, message: 'count of householdIDs should not be more than 10' });
      // }
  
      return true;
    });
  }
  Yup.addMethod(Yup.mixed, "validatePushNotificationHousehold", isValidPushHouseHold);

  const isPushNotificationRequiredOptions = [
    { key: 'Yes', value: '1' },
    { key: 'No', value: '0' },
  ]
  function getEnv(){
    let arr =[]
    arr.push({ key: 'Select an option', value: '' })
    if(process.env.REACT_APP_BANNER_PUSH_API.indexOf('/qa/')!=-1){
      arr.push({ key: 'QA', value: 'QA' })
    }else{
      arr.push({ key: 'PROD', value: 'PROD' })
      arr.push({ key: 'BETA', value: 'BETA' })
    }
    return arr
  }
  const envOptions = getEnv()
  const businessUnitdropdownOptions = [
    { key: 'Select an option', value: '' },
    // { key: 'EMM', value: 'EMM' },
    // { key: 'iTAM', value: 'iTAM' },
    { key: 'NCA', value: 'NCA' },
    //{ key: 'NIT', value: 'NIT' },
  ]
  const countryCodedropdownOptionsEMM = [
    { key: 'Select an option', value: '' },
    { key: 'US', value: '1' },
    { key: 'UK', value: '44' },
    { key: 'Japan', value: '81' },
    { key: 'Australia', value: '61' },
    { key: 'Germany', value: '49' },
    { key: 'Italy', value: '39' }
  ]
  const countryCodedropdownOptionsITAM = [
    { key: 'Select an option', value: '' },
    { key: 'Denmark', value: '45' },
    { key: 'Sweden', value: '46' },
    { key: 'Norway', value: '47' }
  ]
  const countryCodedropdownOptionsNCAAndNIT = [
    { key: 'Select an option', value: '' },
    { key: 'US', value: '1' }
  ]
  const commandsDropdownOptions = [
    { key: 'Select an option', value: '' },
    { key: 'Reg', value: 'heartbeat' },
    { key: 'Unreg', value: 'fdl' },
    { key: 'getEventLog', value: 'unreg' },
    { key: 'gerFDL', value: 'unreg' },
    { key: 'Uninstall', value: 'unreg' },
    { key: 'goSilent', value: 'unreg' },
    { key: 'meter', value: 'unreg' },
    { key: 'eula', value: 'eula' }
  ]
  const radioOptions = [
    { key: 'Reg', value: 'reg' },
    { key: 'Un-Reg', value: 'unreg' },
    { key: 'Eventlog', value: 'eventlog' }
  ]
  const checkboxOptions = [
    { key: 'Heartbeat', value: 'heartbeat' },
    { key: 'Eventlog', value: 'eventlog' },
    { key: 'FDL', value: 'fdl' }
  ]
  const initialValues = {
    // description: '',
    
  }
  // const validateMobile = (ee) => {
  //   console.log('****** validateMobile@123 ****** : '+ee)
  //   var message = 'test'
  //   return this.test("isValidMobile", message, function (value) {
  //     console.log('****** validateMobile ****** : '+value)
  //     const { path, createError } = this;
  //     if (!value) {
  //       return createError({ path, message: message });
  //     }
  
  //     if (value.length < 3) {
  //       return createError({ path, message: message });
  //     }
  //     return true;

  //   })
  // }
  // Yup.addMethod(Yup.mixed, 'validateMobile', (args) => {
  //      console.log('***** validateMobile **********8'+args)
  // })

  const onFileChange = (event) => {
    var ff=event.target.files[0]
    var z = document.getElementById("notification_error_file_div");
    if(ff && ff.name.endsWith('.csv')){
      setNotificationInputFile(ff);
      z.style.display = "none";
    }else if(ff){
      setNotificationInputFile(null);
      z.style.display = "block";
    }else{
      setNotificationInputFile(null);
      z.style.display = "none";
    }
  };

  const validationSchema = Yup.object({
    CountryCode: Yup.string().required('Required'),
    householdID: Yup.mixed().validatePushNotificationHousehold('Enter Valid MobileID'),
    notification_message: Yup.string().required('Required'),
    is_push_notification_required: Yup.string().required('Required'),
    env: Yup.string().required('Required'),
    startDate: Yup.date().max(new Date(), "Start date field must be at earlier than "+formatDate(new Date())).required('Required'),
    endDate: Yup.date().max(new Date(), "End date field must be at earlier than "+formatDate(new Date())).required('Required')
  })
  function formatDate(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-');
  }
  const readFile = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
        reader.onloadend = function(event) {
        resolve(event.target.result)
      };
      reader.readAsText(file);
    })
  }
  const onSubmit = async values => {
    if(bu!='EMM' && bu!='iTAM' && bu!='NCA' && bu!='NIT')
      return
    if (window.confirm('Are you sure you want to process this request?')) {
      var obj = JSON.parse(JSON.stringify(values))
      var data = {
        "countrycode": obj.CountryCode,
        "businessunit": bu,
        "env": obj.env,
        "activityname": "bannerpush",
        "notificationmessage":obj.notification_message,
        "ispushnotificationrequired":obj.is_push_notification_required[0],
        "startdate": formatDate(new Date(values.startDate)),
        "enddate": formatDate(new Date(values.endDate)),
        "username":'local_user'
      };
      var ids=obj.householdID
      if(user)
        data.username = user.name
      if(!ids)
        ids=''
      else
        ids=ids+','
      if(inputNotificationFile && inputNotificationFile.name.endsWith('.csv')){
          var content = await readFile(inputNotificationFile)
          content.split('\n').forEach(s=>{
            if(s!=''){
              s.split(',').forEach(s1=>{
                if(s1.replaceAll('\r','')!='')
                  ids=ids+s1.replaceAll('\r','')+','
              })
            }
          })
      }
      if(ids.endsWith(','))
        ids=ids.substring(0,ids.lastIndexOf(','))
      if(ids.split(',').length>process.env.REACT_APP_BANNER_PUSH_INPUT_COUNT){
        var rr="MobileIDs"
        onError('total count of '+rr+' cannot exceed '+process.env.REACT_APP_BANNER_PUSH_INPUT_COUNT);
        return
      }
      data.mobileids = ids
      const headers = {
        'Content-Type': 'application/json'
      };
      try {
        setIsLoading(true);
        //console.log('finalValues data@123', data);;
        await createRequest(process.env.REACT_APP_BANNER_PUSH_API,data, headers);
        history.push("/");
      } catch (e) {
        onError(e);
        setIsLoading(false);
        var x = document.getElementById("alert_info");
        x.style.display = "none";
        var y = document.getElementById("alert_success");
        y.style.display = "none";
        var z = document.getElementById("alert_error");
        z.style.display = "none";
      }
    } else{

    }
    

  }

  async function getHeader(url, headers) {
    return axios.get(url, {headers})
    .then(res => {
      console.log(res)
    })
  }
  async function createRequest(url,data, headers) {
    return axios.post(url, data, {headers})
    .then(res => {
      // console.log('******* response11 ********');
      // console.log(res);
      if(res.status === 200){
        // console.log("############### Status #############", res.status);
        // console.log("Response =>", res);
        // console.log("Response data => ", res.data);
        setResponsemessage(res.data);
        setIsLoading(false);
        var x = document.getElementById("alert_info");
        x.style.display = "none";
        var y = document.getElementById("alert_success");
        y.style.display = "block";
        var z = document.getElementById("alert_error");
        z.style.display = "none";
      }else{
        // console.log("############### Status error #############", res);
        // console.log("Response =>", res);
        //console.log("Response data => ", res.data);
        var x = document.getElementById("alert_info");
        x.style.display = "none";
        var y = document.getElementById("alert_success");
        y.style.display = "none";
        var z = document.getElementById("alert_error");
        z.style.display = "block";
        var errorMessage = res.data;
          // if(res.status === 401){
          //   errorMessage = 'Invalid Mobile ID!';
          // }else if(res.status === 402){
          //   errorMessage = 'Invalid platform Id!';
          // }else if(res.status === 201){
          //   errorMessage = 'No logs present for the given dates!';
          // }
          setResponsemessage(errorMessage);
          setIsLoading(false);
      }
    })
  }

  const buChnage = async event => {
    var ss=event.target.value
    setBU(ss)
    if(ss=='EMM'){
      setCountryCodeOptions(countryCodedropdownOptionsEMM)
    }else if(ss=='iTAM'){
      setCountryCodeOptions(countryCodedropdownOptionsITAM)
    }
    else{
      setCountryCodeOptions(countryCodedropdownOptionsNCAAndNIT)
    }
      
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '39%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className='Formikcss'>
      <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => (
        <Form>
          <FormikControl
            control='select'
            label='Business Unit'
            name='BusinessUnit'
            options={businessUnitdropdownOptions}
            onChange={buChnage}
          />
          <FormikControl
            control='select'
            label='Country'
            name='CountryCode'
            options={countryCodeOptions}
          />
          <FormikControl
            control='select'
            label='ENV'
            name='env'
            options={envOptions}
          />
          <FormikControl
            control='input'
            //type='input'
            label='MobileID'
            name='householdID'
          />
          <Box marginBottom={1} border={0}>
            <input
              type='file'
              label='Input File'
              name='file'
              id='file'
              title="CSV should have comma separated PanelistIDs"
              onChange={onFileChange}
            />
            <div id='notification_error_file_div' style={{display:'none'}} >Invalid File Passed</div>
          </Box>
          <FormikControl
            control='input'
            //type='input'
            label='Notification Message'
            name='notification_message'
          />
          <FormikControl
            control='radio'
            //type='input'
            label='Send PushNotificaiton'
            name='is_push_notification_required'
            options={isPushNotificationRequiredOptions}
          />
          <FormikControl
            control='date'
            label='Start Date'
            name='startDate'
          />
          <FormikControl
            control='date'
            label='End Date'
            name='endDate'
          />
          <button type='submit'>Banner Push</button>
        </Form>
      )}
    </Formik>

    <div>
    {/* {isLoading ? <BarLoader size={10} color='blue' loading/> : (responsemessage) } */}
    {isLoading ? <BarLoader size={48} color='blue' loading/> : ("") }
    </div>
    <br/>
      <div style={{display:'none'}} id="alert_error" >
        <Alert variant="filled" severity="error"><h4>{(responsemessage)}</h4></Alert>
      </div>
      <div  style={{display:'none'}} id="alert_success" >
        <Alert variant="filled"  severity="success"> <h4>{(responsemessage)}</h4></Alert>
      </div>
      <div  style={{display:'none'}} id="alert_info" >
        <Alert variant="filled"  severity="info"> <h4>{(responsemessage)}</h4></Alert>
      </div>

    </div>
  )
}

export default BannerPush
