import { combineReducers } from 'redux'

import addUser from './SaveUser'
import saveAuth from './SaveAuth'

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  user: addUser,
  auth: saveAuth
})

export default rootReducer