import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";

const useAuthUser = () => {
        const [userInfo, setUserInfo] = useState(null);
        const { oktaAuth, authState } = useOktaAuth();
        
        useEffect(() => {
                const getUser = async () => {
                        try {
                                const res = await oktaAuth.getUser();
                                setUserInfo(res);
                        } catch (error) {
                                console.log(error);
                        }
                };

                authState?.isAuthenticated && getUser();
        }, [authState, oktaAuth]);

        return userInfo;
};

export default useAuthUser;