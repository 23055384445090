const initialState = {
    value:{}
  }
  
  export default function addUser(state = initialState, action) {
    if(action.type=='user/addUser'){
      return {value:action.payload}
    }else{
      return initialState
    }
  }