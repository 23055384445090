import React,{ useEffect,useState}  from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../components/FormikControl';
import { BarLoader} from 'react-spinners'
import Alert from '@material-ui/lab/Alert';
// import { Box, Button, Card, CardContent, Checkbox, CheckboxProps, FormControlLabel, FormGroup, MenuItem, TextField, Typography } from '@material-ui/core';
import "../containers/Formikcss.css";
import "../containers/Login.css";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { onError } from "../libs/errorLib";
import { Box } from '@material-ui/core';
import { useAppContext } from "../libs/contextLib";

function UserActivity () {
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [responsemessage, setResponsemessage] = useState(null);
  const [userActivity, setUserActivity] = useState(null);
  const history = useHistory();
  useEffect(() => { 
    async function load() {
      var data = {}
      data.username = user.name
      const headers = {
        'Content-Type': 'application/json'
      }
      try{
        setIsLoading(true);
        await createRequest(process.env.REACT_APP_USER_ACTIVITY_API,data, headers)
        setIsLoading(false);
      }catch(e){
        onError(e);
        setIsLoading(false);
        var x = document.getElementById("alert_info");
        x.style.display = "none";
        var y = document.getElementById("alert_success");
        y.style.display = "none";
        var z = document.getElementById("alert_error");
        z.style.display = "none";
        //setTestdata()
        //setUserActivity([])
      }
      
     }
    load()
    }, [])
    function setTestdata() {

      setUserActivity(sortArr([
        {
          'activityname':'test1',
          'countrycode':'1',
          'processed_ts_utc':'2024-01-01T12:48:33'
        },
        {
          'activityname':'test1',
          'countrycode':'1',
          'processed_ts_utc':'2024-01-03T12:48:33'
        },
        {
          'activityname':'test2',
          'countrycode':'1',
          'processed_ts_utc':'2024-01-01T13:48:33'
        }
      ]))
    }
    function sortArr(arr) {
      return arr.sort((a, b) => {
        if (b.processed_ts_utc < a.processed_ts_utc)
          return -1;
        if (b.processed_ts_utc > a.processed_ts_utc)
          return 1;
        return 0;
      })
    }
    async function createRequest(url,data, headers) {
      return axios.post(url, data, {headers})
      .then(res => {
        // console.log('******* response11 ********');
        // console.log(res);
        if(res.status === 200){
          console.log("############### Status #############", res.status);
          console.log("Response =>", res);
          console.log("Response data => ", res.data);
          setUserActivity(sortArr(res.data));
          setIsLoading(false);
          var x = document.getElementById("alert_info");
          x.style.display = "none";
          var y = document.getElementById("alert_success");
          y.style.display = "block";
          var z = document.getElementById("alert_error");
          z.style.display = "none";
        }else{
          // console.log("############### Status error #############", res);
          // console.log("Response =>", res);
          //console.log("Response data => ", res.data);
          var x = document.getElementById("alert_info");
          x.style.display = "none";
          var y = document.getElementById("alert_success");
          y.style.display = "none";
          var z = document.getElementById("alert_error");
          z.style.display = "block";
          var errorMessage = res.data;
            // if(res.status === 401){
            //   errorMessage = 'Invalid Mobile ID!';
            // }else if(res.status === 402){
            //   errorMessage = 'Invalid platform Id!';
            // }else if(res.status === 201){
            //   errorMessage = 'No logs present for the given dates!';
            // }
            setResponsemessage(errorMessage);
            setIsLoading(false);
        }
      })
    }  
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '39%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    names: { 'font-weight': 'bold' }
  }));
  const back = async () => {
    history.push('/')
  }
  const classes = useStyles();
  return (
    <div className='Formikcss'>
        {userActivity && userActivity.length>0? (
              <table border='2'>
                <tr><td><span class={classes.names}>countrycode</span></td><td><span class={classes.names}>activityname</span></td> <td><span class={classes.names}>request</span></td><td><span class={classes.names}>response_code</span></td> <td><span class={classes.names}>processed_ts_utc</span></td></tr>
                {userActivity.map((obj,i) =>{
                  var ss=obj.requestmessage
                  var req_mess=ss
                   var flag=(req_mess.indexOf('\\')!=-1)
                   var jss=req_mess
                   if(flag && req_mess && req_mess!=''){
                     jss=req_mess.substring(1,req_mess.length-1)
                     jss = jss.replaceAll('\\','')
                    //  var rr=JSON.parse(jss)
                    //  delete rr['countrycode'];
                    //  delete rr['activityname'];
                    //  delete rr['username'];
                    //  jss=JSON.stringify(rr)
                  }
                  return (<tr>
                    <td>{obj.countrycode}</td><td>{obj.activityname}</td> <td>{jss}</td> <td>{obj.httpresponsecode}</td> <td>{obj.processed_ts_utc}</td>
                  </tr>)}
                )}
              </table>
            ):(
              userActivity && userActivity.length==0? (<span class={classes.names}>No data found for this user</span>):(<></>)
            )}
      <div>
      {isLoading ? <BarLoader size={48} color='blue' loading/> : ("") }
    </div>
    <br/>
      <div style={{display:'none'}} id="alert_error" >
        <Alert variant="filled" severity="error"><h4>{(responsemessage)}</h4></Alert>
      </div>
      <div  style={{display:'none'}} id="alert_success" >
        <Alert variant="filled"  severity="success"> <h4>{(responsemessage)}</h4></Alert>
      </div>
      <div  style={{display:'none'}} id="alert_info" >
        <Alert variant="filled"  severity="info"> <h4>{(responsemessage)}</h4></Alert>
      </div>
       <input type='button' value ='Main Page' onClick={back}/>
    </div>
  )
}

export default UserActivity
