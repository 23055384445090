export const oktaAuthConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: getRedirectURI(`${window.location.origin}/login`),
  scopes: ["openid", "profile", "email"],
  pkce: true
}

function getRedirectURI(redirectUri){
  
  return redirectUri.replaceAll('http://','https://')
}