import React,{ useState}  from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { Box, TextField } from '@material-ui/core';
import "../containers/Formikcss.css";

function Input (props) {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  
  const { label, name,tooltip, ...rest } = props
  return (
    // <div className='form-control'>
    <div>
    <label htmlFor={name}>{label}
    {tooltip ? (
       <div 
       onMouseEnter={onHover}
       onMouseLeave={onLeave}
       role="button"
       tabIndex="-3"
     >
        &nbsp;{hover ? tooltip : 'help'}
     </div>
      ) : ''}
    </label>
    

    
    <Box marginBottom={1} border={1}>
      <Field id={name} as={TextField} name={name} {...rest} />
    </Box>
    <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Input
