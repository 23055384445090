import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./Routes";
import "./App.css";


function App() {
  const history = useHistory();
  
  useEffect(() => {
    //onLoad();
  }, []);

return (
    // !isAuthenticating && (
      <div className="App container">
        <ErrorBoundary>
           <Routes />
        </ErrorBoundary>
      </div>
    //)
  );
}

export default App;