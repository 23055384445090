import React from "react";
import { Route, Switch , useHistory} from "react-router-dom";

import {Home,Home_Okta, User_Activity} from "./containers/Home";
import NotFound from "./containers/NotFound";
import store from "./containers/store";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { oktaAuthConfig } from "./ockta_config";


export default function Routes() {
  const oktaAuth = new OktaAuth(oktaAuthConfig);
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log(toRelativeUrl(originalUri || "/", window.location.origin))
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

if(oktaAuthConfig.redirectUri.indexOf('localhost')!==-1){
  let obj ={oktaAuth:null,authState:null,user:{'name':'local_user'}}
  store.dispatch({ type: 'auth/saveAuth', payload: obj })
  return (
    <Switch>
      <Route exact={true} path="/">
        <Home />
      </Route>
      <Route exact={true} path="/UserActivity">
        <User_Activity />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}else{
  return (
    <Security oktaAuth={oktaAuth} 
    restoreOriginalUri={restoreOriginalUri}>
     <Switch>
      <SecureRoute exact={true} path="/">
        <Home_Okta />
      </SecureRoute>
      <SecureRoute exact={true} path="/UserActivity">
        <User_Activity />
      </SecureRoute>
      <Route path="/login" component={LoginCallback} />
      <SecureRoute exact={true}>
        <NotFound />
      </SecureRoute>
    </Switch>
    </Security>
  );
}
  
}