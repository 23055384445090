import React,{ useState}  from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../components/FormikControl';
import { BarLoader} from 'react-spinners'
import Alert from '@material-ui/lab/Alert';
// import { Box, Button, Card, CardContent, Checkbox, CheckboxProps, FormControlLabel, FormGroup, MenuItem, TextField, Typography } from '@material-ui/core';
import "../containers/Formikcss.css";
import "../containers/Login.css";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { onError } from "../libs/errorLib";
import { Box } from '@material-ui/core';
import { useAppContext } from "../libs/contextLib";

function FraudUpdate () {
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [responsemessage, setResponsemessage] = useState(null);
  const [fraudType, setFraudType] = useState('asd');
  const [inputFraudFile, setFraudInputFile] = useState(null);
  const [bu, setBU] = useState('');
  const history = useHistory();

  const onFileChange = (event) => {
    var ff=event.target.files[0]
    var z = document.getElementById("fraud_error_file_div");
    if(ff && ff.name.endsWith('.csv')){
      setFraudInputFile(ff);
      z.style.display = "none";
    }else if(ff){
      setFraudInputFile(null);
      z.style.display = "block";
    }else{
      setFraudInputFile(null);
      z.style.display = "none";
    }
  };

  const buChnage = async event => {
    var ss=event.target.value
    setBU(ss)
  }
  
  function isValidMobileID(message) {
    return this.test("isValidMobileID", message, function (value) {
      const { path, createError } = this;
  
      if (!inputFraudFile && !value) {
        if(fraudType != 'PanelistID')
          return createError({ path, message: 'Enter Valid MobileID' });
        else
          return createError({ path, message: 'Enter Valid PanelistID' });
      }
      var exp=/[^\d,_]+/g
      if(bu && bu.toLowerCase()=='itam')
        exp=/[^a-zA-Z\d,-_]+/g
      var flag=exp.test(value)
      
      if (value && flag) {
        if(fraudType != 'PanelistID')
          return createError({ path, message: 'Enter Valid MobileID' });
        else
          return createError({ path, message: 'Enter Valid PanelistID' });
      }
      // if(value.split(',').length>10){
      //   if(fraudType == 'PanelistID')
      //     return createError({ path, message: 'count of panelistids should not be more than 10' });
      //   else
      //   return createError({ path, message: 'count of mobileids should not be more than 10' });
      // }
  
      return true;
    });
  }
  Yup.addMethod(Yup.mixed, "validateMobile", isValidMobileID);
  
  const businessUnitdropdownOptions = [
    { key: 'Select an option', value: '' },
    { key: 'EMM', value: 'EMM' },
  ]
  const fraudTypeOptions = [
    { key: 'Select an option', value: '' },
    { key: 'panelist-level', value: 'panelist-level' },
    { key: 'device-level', value: 'device-level' }
  ]
  const countryCodedropdownOptions = [
    { key: 'Select an option', value: '' },
    { key: 'US', value: '1' },
    { key: 'UK', value: '44' },
    { key: 'Japan', value: '81' },
    { key: 'Australia', value: '61' },
    { key: 'Germany', value: '49' },
    { key: 'Italy', value: '39' }
  ]
  const commandsDropdownOptions = [
    { key: 'Select an option', value: '' },
    { key: 'Reg', value: 'heartbeat' },
    { key: 'Unreg', value: 'fdl' },
    { key: 'getEventLog', value: 'unreg' },
    { key: 'gerFDL', value: 'unreg' },
    { key: 'Uninstall', value: 'unreg' },
    { key: 'goSilent', value: 'unreg' },
    { key: 'meter', value: 'unreg' },
    { key: 'eula', value: 'eula' }
  ]
  const radioOptions = [
    { key: 'Reg', value: 'reg' },
    { key: 'Un-Reg', value: 'unreg' },
    { key: 'Eventlog', value: 'eventlog' }
  ]
  const checkboxOptions = [
    { key: 'Heartbeat', value: 'heartbeat' },
    { key: 'Eventlog', value: 'eventlog' },
    { key: 'FDL', value: 'fdl' }
  ]
  const initialValues = {
    // description: '',
    
  }
  const validationSchema = Yup.object({
    CountryCode: Yup.string().required('Required'),
    //BusinessUnit: Yup.string().required('Required'),
    ID: Yup.mixed().validateMobile('Enter Valid MobileID')
  })
  const fraudTypeChnage = async event => {
    var ss=event.target.value
    if(ss=='panelist-level')
      setFraudType('PanelistID')
    else
      setFraudType('MobileID')
    var z = document.getElementById("mobile_div");
    z.style.display = "block";
  }
  const readFile = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
        reader.onloadend = function(event) {
        resolve(event.target.result)
      };
      reader.readAsText(file);
    })
  }
  const onSubmit = async values => {
    if(bu!='EMM' && bu!='iTAM')
      return
    if (window.confirm('Are you sure you want to process this request?')) {
      var obj = JSON.parse(JSON.stringify(values))
      var data = {
        "countrycode": obj.CountryCode,
        "businessunit": bu
      };
      var ids=obj.ID
      data.activityname = 'fraudupdate'
      data.username = 'local_user'
      if(user)
        data.username = user.name
      const headers = {
        'Content-Type': 'application/json'
      };
      if(!ids)
        ids=''
      else
        ids=ids+','
      if(inputFraudFile && inputFraudFile.name.endsWith('.csv')){
        var content = await readFile(inputFraudFile)
        content.split('\n').forEach(s=>{
          if(s!=''){
            s.split(',').forEach(s1=>{
              if(s1.replaceAll('\r','')!='')
                ids=ids+s1.replaceAll('\r','')+','
            })
          }
        })
      }
      if(ids.endsWith(','))
        ids=ids.substring(0,ids.lastIndexOf(','))
      if(ids.split(',').length>process.env.REACT_APP_FRAUD_INPUT_COUNT){
        var rr="mobileids"
        if(fraudType != 'MobileID')
           rr="panelistids"
        onError('total count of '+rr+' cannot exceed '+process.env.REACT_APP_FRAUD_INPUT_COUNT);
        return
      }
      if(fraudType == 'MobileID'){
        data.mobileids = ids
      }else{
        data.panelistids = ids
      }
      try {
        setIsLoading(true);
        await createRequest(process.env.REACT_APP_FRAUD_API,data, headers);
        history.push("/");
      } catch (e) {
        onError(e);
        setIsLoading(false);
        var x = document.getElementById("alert_info");
        x.style.display = "none";
        var y = document.getElementById("alert_success");
        y.style.display = "none";
        var z = document.getElementById("alert_error");
        z.style.display = "none";
      }
    } else{

    }
    

  }

  async function createRequest(url,data, headers) {
    return axios.post(url, data, {headers})
    .then(res => {
      // console.log('******* response11 ********');
      // console.log(res);
      if(res.status === 200){
        // console.log("############### Status #############", res.status);
        // console.log("Response =>", res);
        // console.log("Response data => ", res.data);
        setResponsemessage(res.data);
        setIsLoading(false);
        var x = document.getElementById("alert_info");
        x.style.display = "none";
        var y = document.getElementById("alert_success");
        y.style.display = "block";
        var z = document.getElementById("alert_error");
        z.style.display = "none";
      }else{
        // console.log("############### Status error #############", res);
        // console.log("Response =>", res);
        //console.log("Response data => ", res.data);
        var x = document.getElementById("alert_info");
        x.style.display = "none";
        var y = document.getElementById("alert_success");
        y.style.display = "none";
        var z = document.getElementById("alert_error");
        z.style.display = "block";
        var errorMessage = res.data;
          // if(res.status === 401){
          //   errorMessage = 'Invalid Mobile ID!';
          // }else if(res.status === 402){
          //   errorMessage = 'Invalid platform Id!';
          // }else if(res.status === 201){
          //   errorMessage = 'No logs present for the given dates!';
          // }
          setResponsemessage(errorMessage);
          setIsLoading(false);
      }
    })
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '39%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className='Formikcss'>
      <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => (
        <Form>
          <FormikControl
            control='select'
            label='Business Unit'
            name='BusinessUnit'
            options={businessUnitdropdownOptions}
            onChange={buChnage}
            title="This is the full title of the label"
          />
          <FormikControl
            control='select'
            label='Country'
            name='CountryCode'
            options={countryCodedropdownOptions}
          />
          <FormikControl
            control='select'
            label='FraudType'
            name='FraudType'
            options={fraudTypeOptions}
            onChange={fraudTypeChnage}
          />
          <div style={{display:'none'}} id="mobile_div" >
            <FormikControl
              control='input'
              //type='input'
              label={fraudType}
              name='ID'
            />
          </div>

          <div>
          <Box marginBottom={1} border={0}>
            <input
              type='file'
              label='Input File'
              name='file'
              id='file'
              title="CSV should have comma separated mobileids/panelistids"
              onChange={onFileChange}
            />
            <div id='fraud_error_file_div' style={{display:'none'}} >Invalid File Passed</div>
          </Box>
          </div>

          <button type='submit'>Fraud Update</button>
        </Form>
      )}
    </Formik>

    <div>
    {/* {isLoading ? <BarLoader size={10} color='blue' loading/> : (responsemessage) } */}
    {isLoading ? <BarLoader size={48} color='blue' loading/> : ("") }
    </div>
    <br/>
      <div style={{display:'none'}} id="alert_error" >
        <Alert variant="filled" severity="error"><h4>{(responsemessage)}</h4></Alert>
      </div>
      <div  style={{display:'none'}} id="alert_success" >
        <Alert variant="filled"  severity="success"> <h4>{(responsemessage)}</h4></Alert>
      </div>
      <div  style={{display:'none'}} id="alert_info" >
        <Alert variant="filled"  severity="info"> <h4>{(responsemessage)}</h4></Alert>
      </div>

    </div>
  )
}

export default FraudUpdate
