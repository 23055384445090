import React, { useState, useEffect } from "react";
// import React from "react";
//import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { Nav, Navbar, NavItem } from "react-bootstrap";

import "./Home.css";
import renderLander from "./Lander.js";
// import  VerticalTabs  from "./Tabs.js";
import  {ShowUserActivity,ScrollableTabsButtonForce}  from "./Card.js";
import { useOktaAuth } from "@okta/okta-react";
import useAuthUser from "./getUser";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../libs/contextLib";
import store from './store'

function renderRequests(user,isUserActivity) {
  return (
    <div className="requests">
      <AppContext.Provider value={{ user }}>
        {
          isUserActivity?(
             <ShowUserActivity />
          ):(
             <ScrollableTabsButtonForce />
          )
        }
        
        </AppContext.Provider>  
    </div>
  );
}

export function User_Activity() {
  const history = useHistory();
  const userInfo = store.getState().auth.value.user;
  const oktaAuth = store.getState().auth.value.oktaAuth
  const authState = store.getState().auth.value.authState
  const handleLogin = async () => {
    oktaAuth.signInWithRedirect({ originalUri: "/" });
  }
  const handleLogout = async () => {
    oktaAuth.signOut();
  }
 return (
    <div>
      { <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
            {userInfo? (
              <Link to="/">PCC Welcome , {userInfo?.name}</Link>
            ):(
              <Link to="/">PCC</Link>
            )}
             
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {authState?(authState?.isAuthenticated ? (
                <>
                  <NavItem onClick={handleLogout}>Logout</NavItem>
                </>
              ) : (
                <>
                  <LinkContainer to="/login">
                    <NavItem onClick={handleLogin}>Login</NavItem>
                  </LinkContainer>
                </>
              )):(
                <>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>  }
      <div className="Home">
        {renderRequests(userInfo,true)}
      </div>
    </div>
  );
}
export function Home_Okta() {
  const userInfo = useAuthUser();
  const { oktaAuth, authState } = useOktaAuth();
  if(userInfo!=null){
    let obj ={oktaAuth:oktaAuth,authState:authState,user:userInfo}
    store.dispatch({ type: 'auth/saveAuth', payload: obj })
  }
  if(userInfo!=null)
    return Home()
  else
    return (<div/>)
}
export function Home() {
  const history = useHistory();
  const userInfo = store.getState().auth.value.user;
  const oktaAuth = store.getState().auth.value.oktaAuth
  const authState = store.getState().auth.value.authState
  
  const handleLogin = async () => {
    oktaAuth.signInWithRedirect({ originalUri: "/" });
  }
  const handleLogout = async () => {
    oktaAuth.signOut();
  }
  const userActivity = async () => {
    history.push('/UserActivity')
  }
return (
    <div>
      { <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
            {userInfo? (
              <Link to="/">PCC Welcome , {userInfo?.name}</Link>
            ):(
              <Link to="/">PCC</Link>
            )}
             
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {authState?(authState?.isAuthenticated ? (
                <>
                  <NavItem onClick={handleLogout}>Logout</NavItem>
                  <NavItem onClick={userActivity}>User Activity</NavItem>
                </>
              ) : (
                <>
                  <LinkContainer to="/login">
                    <NavItem onClick={handleLogin}>Login</NavItem>
                  </LinkContainer>
                </>
              )):(
                <>
                  <NavItem onClick={userActivity}>User Activity</NavItem>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar> }
      <div className="Home">
        {renderRequests(userInfo,false)}
      </div>
    </div>
  );
}