import React from "react";
import { Link } from "react-router-dom";

function renderLander() {
    return (
      <div className="lander">
        <h1>PCC</h1>
        <p>Utility Application Made by Mobile Collections Team</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
        </div>
      </div>
    );
  }

export default renderLander;