const initialState = {
    value:{}
  }
  
  export default function saveAuth(state = initialState, action) {
    if(action.type=='auth/saveAuth'){
      return {value:action.payload}
    }else{
      return initialState
    }
  }