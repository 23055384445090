import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { Box } from '@material-ui/core';

function Select (props) {
  const { label, name, options, ...rest } = props
  return (
    // <div className='form-control'>
    <Box marginBottom={2}>
      <label htmlFor={name}>{label}</label>
      <Field as='select' id={name} name={name} {...rest}>
      {/* <Field as={TextField} select id={name} name={name} {...rest}> */}
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          )
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </Box>
  )
}

export default Select
